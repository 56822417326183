import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb92787a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "window"
}
const _hoisted_2 = { class: "frame" }
const _hoisted_3 = { class: "stack" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "build-error-overlay",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.data = null), ["self"]))
  }, [
    ($setup.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.formatted, (error, i) => {
            return (_openBlock(), _createElementBlock("div", { key: i }, [
              _createCommentVNode(" prettier-ignore "),
              _createElementVNode("pre", _hoisted_2, [
                (_openBlock(), _createBlock(_resolveDynamicComponent($setup.error_component(error, i))))
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createElementVNode("pre", _hoisted_3, _toDisplayString($setup.data.stack), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */)), [
    [_vShow, $setup.data]
  ])
}